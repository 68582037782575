import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    constructor(private http: HttpClient) { }

    captchaToken: string;

    contactForm = new FormGroup({
        name: new FormControl('', Validators.nullValidator && Validators.required),
        emailAddress: new FormControl('', Validators.nullValidator && Validators.required),
        phoneNumber: new FormControl('', Validators.nullValidator && Validators.required),
        message: new FormControl('', Validators.nullValidator && Validators.required)
      });

    success = '';

    rootURL = '/api';
    
    onSubmit() {               
        return this.http.post(this.rootURL + '/contact', {
                "contact": this.contactForm.value, "recaptcha": this.captchaToken})
            .subscribe({
            next: data => {
                if (data['success']) {
                    this.success = 'Your contact request has been submitted.';
                    this.contactForm.reset();
                } else 
                    this.success = data['message'];

            },
            error: error => console.error('There was an error!', error)
        });
    }

    //function to resolve the reCaptcha and retrieve a token
    resolved(captchaResponse: string) {
        this.captchaToken = captchaResponse;
        console.log('Resolved response token: ${captchaResponse}');
    }

    ngOnInit(){
        // Smooth scrolling using jQuery easing
        $('a.js-scroll-trigger[href*="#"]:not([href="#"])').click(function () {
        if (
            location.pathname.replace(/^\//, "") ==
                this.pathname.replace(/^\//, "") &&
            location.hostname == this.hostname
        ) {
            var target = $(this.hash);
            target = target.length
                ? target
                : $("[name=" + this.hash.slice(1) + "]");
            if (target.length) {
                $("html, body").animate(
                    {
                        scrollTop: target.offset().top,
                    },
                    1000,
                    "easeInOutExpo"
                );
                return false;
            }
        }
  });

  // Closes responsive menu when a scroll trigger link is clicked
  $(".js-scroll-trigger").click(function () {
      $(".navbar-collapse").collapse("hide");
  });

  // Activate scrollspy 
  }
}
