<!-- Navigation-->
<nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
  <a class="navbar-brand js-scroll-trigger" href="#page-top">
      <span class="d-block d-lg-none">Surrey Software Limited</span>
      <span class="d-none d-lg-block"><img class="img-fluid img-profile rounded-circle mx-auto mb-2" src="assets/img/me.jpg" alt="" /></span>
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span class="navbar-toggler-icon"></span></button>
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav">
          <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#about">About</a></li>
          <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#services">Services</a></li>                    
          <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#customers">Customers</a></li>
          <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#skills">Skills</a></li>
          <li class="nav-item"><a class="nav-link js-scroll-trigger" href="#contact">Contact Us</a></li>
      </ul>
  </div>
</nav>
<!-- Page Content-->
<div class="container-fluid p-0">
  <!-- About-->
  <section class="resume-section" id="about">
      <div class="resume-section-content logo">
          <div>
              <img src="assets/img/SurreySoftwareLogov2.png"/>
          </div>
          <p>Surrey Software provides a Full Stack and Sharepoint Development and Cloud Consultancy service offering. We have a wealth of experience developing solutions, leading and managing projects and providing advice and solution architecture.</p>
          <p>We also have extensive experience of Server side technologies including .NET, Sharepoint SPFX and NodeJS, Client side technologies including React, Angular and Vue In addition we have a extensive knowledge and experience of Microsoft Azure.</p>   
          <p>We have Microsoft Certified Professional Developers , Azure Solution Architect Experts and Microsoft 365 Certified Developers.</p>                    
          <div class="social-icons">
          <a class="social-icon" href="https://www.linkedin.com/in/tim-bunting-8803a84/"><i class="fab fa-linkedin-in"></i></a>
          <a class="social-icon" href="https://www.facebook.com/profile.php?id=100004910265289"><i class="fab fa-facebook-f"></i></a>
          <a class="social-icon" href="https://g.page/r/CWg0_AeEQw53EBA"><i class="fab fa-google-plus"></i></a>
          </div>
      </div>
  </section>
  <hr class="m-0" />
   <!-- Services-->
   <section class="resume-section" id="services">
      <div class="resume-section-content">
          <h2 class="mb-5">Services</h2>
          <p>Surrey Software has been in business for over 5 years and provides quality software development and consultancy.</p>
          <p class="mb-0">Our staff are qualified Microsoft Professional .NET Developers with Full Stack experience available on a contract or consultancy basis. Contracts can be short or long term and our clients typically renew contracts multiple times.</p>
      </div>
  </section>
  <hr class="m-0" />
  <!-- Our Customers-->
  <section class="resume-section" id="customers">
      <div class="resume-section-content">
          <h2 class="mb-5">Customers</h2>
          <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <a href="https://www.osltechnology.com/">
                    <img class="customer-img" src="assets/img/operational-solutions-logo.jfif"/>
                </a>
                <p>Enhanced existing Counter Drone system used at major UK and international airports including new UI features to support What3Words integration and integration of new “Intelligent” camera.</p>
                <p>Participated in design of new architecture along the lines of microservices and websockets. Developed new Command and Control system to detect, classify and track targets entering Geo Fence.</p>
                <p>Worked on development of a pilot project of a Drone system capable of launching a defensive Drone to track, intercept and ‘capture’ hostile drones entering a restricted area.</p>
            </div>
            <div class="flex-shrink-0"><span class="text-primary">August 2020 - September 2021</span></div>
        </div>
          <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="flex-grow-1">
                <a href="https://www.auktechnology.com/">
                    <img class="customer-img customer-img-shift-left" src="assets/img/auktechnologylogo.jpg" />
                </a>
                <p>Maintained real time trading system for Broker HPC used by leading banks and financial institutions.</p>
                <p>Work on development of Natural Language Trading Product using Rasa Virtual Assistant Technology.</p>
                <p>Also provided User and Account configuration for CryptoCurrency Auk Trading Product.</p>
            </div>
            <div class="flex-shrink-0"><span class="text-primary">August 2020 - September 2021</span></div>
        </div>
          <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
              <div class="flex-grow-1">
                  <a href="https://www.salaryfinance.com/">
                      <img class="customer-img" src="assets/img/sf-advance-logo.png"/>
                  </a>
                  <p>Played a key part in the ‘New Stack’ Development on which all new SF Products are now based.</p>
                  <p>Led development of the SF Pay Advance Application used by employees of many major UK Companies.</p>
                  <p>Architected and developed the joint L&G / SF Employee Flexible Insurance Platform SF Protect.</p>
              </div>
              <div class="flex-shrink-0"><span class="text-primary">August 2018 - June 2020</span></div>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
              <div class="flex-grow-1">
                  <a href="https://www.talentis.global/">
                      <img class="customer-img" src="https://www.talentis.global/wp-content/themes/gated/static/img/logo.svg"/>
                  </a>
                  <p>Part of a small team to design, develop, build and launch the Recruitment GatedTalent WebSite.</p>
                  <p>Achieved high levels of client satisfaction for ingenuity and bringing on new local and remote developers.</p>
                  <p>Provided Scrum Master to lead daily Scrum meetings and organise End of Sprint Demonstrations </p>
              </div>
              <div class="flex-shrink-0"><span class="text-primary">March 2017 to August 2018</span></div>
          </div>
          <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
              <div class="flex-grow-1">
                  <a href="https://www.redkatdesign.com/">
                      <img class="customer-img customer-img-shift-left" src="assets/img/redkat.jfif" />
                  </a>
                  <p>Provided consultancy and Web Development Services to this small Design Studio.</p>
                  </div>
              <div class="flex-shrink-0"><span class="text-primary">2018</span></div>
          </div>                   
      </div>
  </section>
  <hr class="m-0" />           
  <!-- Skills-->
  <section class="resume-section" id="skills">
      <div class="resume-section-content">
          <h2 class="mb-5">Skills</h2>
          <div class="subheading mb-3">Programming Languages & Techniques</div>
          <ul class="list-inline">
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/csharpdotnet.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Over 10 years experience, the last 5 of which using .NET Core.</p>
                      </div>
                  </div>
              </div>
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1 row">
                    <div class="col-sm-3">
                        <img class="skills-img" src="assets/img/sharepointframework.png"/>
                    </div>
                    <div class="col-sm-9">
                        <p>Created Viva Connections, Web Parts, Microsoft Teams Apps and Extensions and Outlook Add-Ins for Microsoft 365 Clients.</p>
                    </div>
                </div>
            </div>
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/microservices.jpg" style="margin-top: -1em;"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Developed Operational Solutions Salary Counter Drone System and New Stack Architecture for Salary Finance products using Containerised Microservices.</p>
                      </div>
                  </div>
              </div>  

              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1 row">
                    <div class="col-sm-3">
                        <img class="skills-img skills-img-flatten" src="assets/img/nodejslogo.png"/>
                    </div>
                    <div class="col-sm-9">
                        <p>Developed Alert Notifications Websocket for OSL Counter Drone System using NodeJS.</p>
                    </div>
                </div>
              </div>  

              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1 row">
                    <div class="col-sm-3">
                        <img class="skills-img" src="assets/img/python-logo.png"/>
                    </div>
                    <div class="col-sm-9">
                        <p>Created services in Python on embedded Raspberry Pi devices.</p>
                    </div>
                </div>
              </div> 

              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/typescript.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Proficient in TypeScript with Vue for development of Employee Benefit Cover Website.</p>
                      </div>
                  </div>
              </div> 
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/javascript.jfif"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Javascript and JQuery used for building websites.</p>
                      </div>
                  </div>
              </div>
          </ul>

          <div class="subheading mb-3">Web Development</div>
          <ul class="list-inline">
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/vuejs.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>VueJS with Nginx WebServer utilised at Salary Finance.</p>
                      </div>
                  </div>
              </div>
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/angular.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Initially developed using AngularJS and over time increased skills in later versions of Angular.</p>
                      </div>
                  </div>
              </div>  

              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/reactjs.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Recently developed skills in ReactJS and Redux State Container.</p>
                      </div>
                  </div>
              </div>                        
          </ul>
          
          <div class="subheading mb-3">Cloud Technologies and Containerisation</div>
          <ul class="list-inline">
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1 row">
                    <div class="col-sm-3">
                        <img class="skills-img" src="assets/img/azure.png"/>
                    </div>
                    <div class="col-sm-9">
                        <p>Certified Solutions Architect Expert in Microsoft Azure. Provided Cloud Migration Consultancy and over 7 years of experience developing solutions in Azure.</p>
                    </div>
                </div>
              </div>  
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/aws.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Extensive use of AWS in recent contract. Working towards AWS Certified Solution Developer.</p>
                      </div>
                  </div>
              </div>              

              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img skills-img-flatten" src="assets/img/docker.jpg"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Docker and Docker Swarm Containerisation approach taken in hosting Microservices at Salary Finance.</p>
                      </div>
                  </div>
              </div>                        
          </ul>
          <div class="subheading mb-3">Data Repositories</div>
          <ul class="list-inline">
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/sqlserver.jfif"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Highly proficient in SQL and developing applications using SQL Server and Azure SQL Databases.</p>
                      </div>
                  </div>
              </div>
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/mysql.png"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Made use of MySQL and AWS Aurora at Auk Technology.</p>
                      </div>
                  </div>
              </div>  
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                  <div class="flex-grow-1 row">
                      <div class="col-sm-3">
                          <img class="skills-img" src="assets/img/redis.jfif"/>
                      </div>
                      <div class="col-sm-9">
                          <p>Developed Salary Finance applications using Redis Distributed Caching and experienced in .NET Redis API libraries.</p>
                      </div>
                  </div>
              </div>  
              <div class="d-flex flex-column flex-md-row justify-content-between mb-5">
                <div class="flex-grow-1 row">
                    <div class="col-sm-3">
                        <img class="skills-img" src="assets/img/rabbitmq.png"/>
                    </div>
                    <div class="col-sm-9">
                        <p>Developed Gas Trading System at Auk Technology using RabbitMQ as a Publisher/Subscriber mechanism to communicate between microservices developed in different languages.</p>
                    </div>
                </div>
            </div>                        
          </ul>
      </div>
  </section>
  <hr class="m-0" />
 
  <!-- Contact-->
  <section class="resume-section" id="contact">
      <div class="resume-section-content">
          <h2 class="mb-5">Contact Us</h2>
          <form id="contactForm" name="sentMessage" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
              <div class="control-group">
                  <div class="form-group floating-label-form-group controls mb-0 pb-2">
                      <label>Name</label>
                      <input class="form-control" id="name" formControlName="name" type="text" placeholder="Name" required="required" data-validation-required-message="Please enter your name." aria-invalid="false">
                      <p class="help-block text-danger"></p>
                  </div>
              </div>
              <div class="control-group">
                  <div class="form-group floating-label-form-group controls mb-0 pb-2">
                      <label>Email Address</label>
                      <input class="form-control" id="email" formControlName="emailAddress" type="email" placeholder="Email Address" required="required" data-validation-required-message="Please enter your email address.">
                      <p class="help-block text-danger"></p>
                  </div>
              </div>
              <div class="control-group">
                  <div class="form-group floating-label-form-group controls mb-0 pb-2">
                      <label>Phone Number</label>
                      <input class="form-control" id="phone" formControlName="phoneNumber" type="tel" placeholder="Phone Number" required="required" data-validation-required-message="Please enter your phone number.">
                      <p class="help-block text-danger"></p>
                  </div>
              </div>
              <div class="control-group">
                  <div class="form-group floating-label-form-group controls mb-0 pb-2">
                      <label>Message</label>
                      <textarea class="form-control" id="message" formControlName="message" rows="5" placeholder="Message" required="required" data-validation-required-message="Please enter a message."></textarea>
                      <p class="help-block text-danger"></p>
                  </div>
              </div>
                <!-- the SITE_KEY is the sitekey from your admin console. use form control for validation -->
                <re-captcha (resolved)="resolved($event)" siteKey="6LdvZqwZAAAAAFB_3fL9VTEV4lkfx6oeUhnGTI0I">  
                </re-captcha>
              <br>
              <div id="success">{{success}}</div>
              <div class="form-group"><button class="btn btn-primary btn-xl" id="sendMessageButton" [disabled]="!contactForm.valid || !captchaToken" type="submit">Send</button></div>
          </form>           
      </div>
  </section>
</div>